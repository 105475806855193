/* * {
  border: 1px solid red !important; 
  padding: 0;
  margin: 0;
} */

/* Desktop Menu Styles */

body {
  overflow-x: hidden;
}

.desktop-menu {
  display: none;
}

@media (min-width: 768px) {
  .desktop-menu {
    display: flex;
    color: white;
    align-items: center;
    justify-content: end;
  }
}

.link {
  text-decoration: none;
  padding: 7px 25px;
  color: white;
  text-transform: uppercase;
  font-size: 16.8px;
  padding-top: 15px;
  margin-right: 15px;
}

.bottom-link {
  text-decoration: none;
  padding: 4px;
  color: white;
}

.link p {
  font-size: 16.8px;
  font-weight: 400;
}

.active-link {
  padding: 5px 30px;
  background: url("/src//assets/button_background.png") no-repeat center;
  background-size: contain !important;
  /* border-radius: 40px / 100px;
  box-shadow: inset -4px -4px 20px rgba(255, 255, 255, 0.2),
    inset 4px 4px 20px rgba(0, 0, 0, 0.3), 0px 5px 15px rgba(0, 0, 0, 0.4); */
  border: none;
  color: white !important;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  outline: none;
  cursor: pointer;
  display: inline-block;
  transition: transform 0.2s ease-in-out;
  text-decoration: none;
  margin-right: 15px;
}

.submit_btn {
  background: url("/src//assets//vertical_submit.png") no-repeat center;
  background-size: contain !important;
}

.contest_btn{
  padding: 5px 30px;
  background: url("/src//assets//viewResultBtn.png") no-repeat center;
  background-size: contain !important;
  color: white !important;
  font-size: 9px;
  text-align: center;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}
.active-link p {
  font-size: 16.8px;
  font-weight: 400;
  text-align: center;
  padding: 3px;
}

/* Mobile Menu Styles */
.drawer-active-link {
  background-color: blue;
  color: red;
  text-decoration: none;
}

/* Hero Image */
.home-hero-image {
  background-image: url("./assets/home_hero.webp");
  background-size: cover;
  background-position: center center;
  height: 50vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  width: 100vw;
  position: absolute;
  top: 0px;
  z-index: -1;
}

/* AppBar Styles */
.MuiAppBar-colorTransparent {
  --Paper-shadow: unset !important;
}

/* Slider */
.slideC {
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  width: 362px;
  height: 272px;
  margin: 0 auto;
}

.slide {
  width: 362px;
  height: 272px;
  transition: transform 500ms ease 0s, opacity 500ms ease 0s,
    visibility 500ms ease 0s;
  position: absolute;
  top: 0;
  /* border-radius: 12px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 3D reflection effect */
.reflection {
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: -60px;
  border-radius: 12px;
  transition: transform 500ms ease 0s, opacity 500ms ease 0s,
    visibility 500ms ease 0s;
}

.sliderContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sliderContent h2 {
  margin: 16px 0;
}
.sliderContent p {
  margin: 0;
  margin-bottom: 16px;
}

/* Default desktop styles for .slide-img */
.slide-img {
  position: relative;
  border-radius: 36px;
  padding: 18px;
  background: linear-gradient(
    89.82deg,
    #c08000 0%,
    #d49016 10%,
    #e0a020 20%,
    #f2c040 30%,
    #ffd060 40%,
    #ffeb90 50%,
    #fcd070 60%,
    #f0b050 70%,
    #d29030 80%,
    #b47020 90%,
    #c08000 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.5);
}

.slide-img img {
  border-radius: 36px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-img-circular {
  position: relative;
  border-radius: 50%;
  padding: 18px;
  background: linear-gradient(
    89.82deg,
    #c08000 0%,
    #d49016 10%,
    #e0a020 20%,
    #f2c040 30%,
    #ffd060 40%,
    #ffeb90 50%,
    #fcd070 60%,
    #f0b050 70%,
    #d29030 80%,
    #b47020 90%,
    #c08000 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-img-circular img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Mobile styles */
@media (max-width: 767px) {
  .slide-img {
    padding: 5.4px; /* Space inside for the content */
    background: linear-gradient(
      89.82deg,
      #c08000 0%,
      #d49016 10%,
      #e0a020 20%,
      #f2c040 30%,
      #ffd060 40%,
      #ffeb90 50%,
      #fcd070 60%,
      #f0b050 70%,
      #d29030 80%,
      #b47020 90%,
      #c08000 100%
    );
    border-radius: 10.8px;
  }

  .slide-img img {
    border-radius: 10.8px;
  }
  .slide-img-circular {
    padding: 5.4px;
    background: linear-gradient(
      89.82deg,
      #c08000 0%,
      #d49016 10%,
      #e0a020 20%,
      #f2c040 30%,
      #ffd060 40%,
      #ffeb90 50%,
      #fcd070 60%,
      #f0b050 70%,
      #d29030 80%,
      #b47020 90%,
      #c08000 100%
    );
    border-radius: 50%;
  }

  .slide-img-circular img {
    border-radius: 50%;
  }
}

/* normal css  */

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.btns {
  /* padding-top: 160px; */
  text-align: center;
}

.btns .btn {
  cursor: pointer;
}

.btns .btn:last-child {
  margin-left: 40px;
}

/* normal css  */

/* Add Navigation Dots */

.dots {
  text-align: center;

  /* margin-top: 10px; */
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #efefef;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-image: linear-gradient(to right, red, orange);
}

.dot:hover {
  background-color: #999;
}
