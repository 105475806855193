.cardContainer {
  display: flex;
  grid-gap: 30px;
  flex-wrap: wrap;
  margin: 40px 0 50px 0;
  justify-content: center;
  background: url("../../assets/desktop_champ_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.cardView {
  height: 390px;
  width: 350px;
  background-color: beige;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 10px grey;
}

.inner-shadow {
  width: 150px;
  height: 150px;
  box-shadow: inset gray 0px 0px 60px -12px;
}

.bannerimg {
  height: 60%;
}

/* Image inside card */
.bannerimg img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Stretches the image to fill the container */
}

/* Overlay styling */
.overlay {
  height: 40%;
  width: 100%;
  z-index: 1;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlayblur {
  height: 7%;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 53%;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.15);
}

.overlay .top {
  height: 17%;
  width: 100%;
}

.overlay .bottom {
  flex: 1;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
}

.blurrr {
  backdrop-filter: blur(45px);
  position: absolute;
  z-index: 2;
  top: 60%;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  font-family: "Somatic";
}

.btn {
  position: absolute;
  z-index: 3;
  opacity: 1;
  bottom: 0;
  top: 0;
  transform: translate(45%, 85%);
}

.topText {
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  width: 100%; 
  text-align: center; 
}

.title {
  text-align: center;
}

.desc {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ageLimit {
  position: absolute;
  top: 75%;
  text-align: center;
}

.detailPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.detailImg  {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.detailName {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(45px);
}

.detail_desc{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail_cardContainer {
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.detail_userEnroll{
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
  padding:20px 0 0 30px;
}

.detail_cardView {
  background-color: beige;
  border-radius: 30px;
  position: relative; 
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 10px grey;
  overflow: hidden; 
  align-items: center;
}

.detail_cardView img {
  height: 100%;
  width: 100%;
  border-radius: 30px;
  object-fit: cover; 
}

.avatarContainer {
  position: absolute;
  bottom: 10px; 
  left: 10px;
  z-index: 1; 
  display: flex;
  align-items: center;
  cursor: pointer;
}

.avatarContainer img {
  border: 2px solid white;
  border-radius: 50%; 
  background-color: white; 
}
.categories {
  display: inline-block; 
  padding: 0 5px 5px;
}

.white-grad {
  background: 
    linear-gradient(#ccc 0 0) padding-box, 
    linear-gradient(
      90deg,
      rgba(224, 39, 128, 0.7),
      rgba(250, 78, 118, 0.7),
      rgba(167, 132, 234, 0.7),
      rgba(236, 167, 92, 0.7),
      rgba(246, 141, 109, 0.7),
      rgba(207, 102, 198, 0.7)
    ) border-box;
  color: #313149;
  border: 2px solid transparent;
  border-radius: 15px;
  display: inline-block;
}

@media only screen and (max-width:375px){
  .detail_cardView{
    width: 125px;
  }
}